var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-container"},[_c('el-table',{ref:"table",attrs:{"data":_vm.provider_list,"border":"","show-header":"","highlight-current-row":"","size":"mini","default-sort":{prop: 'enable', order: 'descending'}},on:{"sort-change":_vm.sortthiscolumn}},[_c('el-table-column',{attrs:{"align":"center","width":"150","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"编辑","placement":"bottom-start"}},[_c('el-button',{attrs:{"size":"mini","plain":""},on:{"click":function($event){return _vm.edit(scope.row)}}},[_c('img',{attrs:{"src":require('@/icons/svg/edit.svg')}})])],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"人员列表","placement":"bottom-start"}},[_c('el-button',{attrs:{"size":"mini","plain":"","disabled":!scope.row.enable},on:{"click":function($event){return _vm.personnel_list(scope.row)}}},[_c('img',{attrs:{"src":scope.row.enable?require('@/icons/svg/users.svg'):require('@/icons/svg/users-disabled.svg')}})])],1)]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"name","sortable":"custom","label":"名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(row.enable?'':'color: #d0d0d0')},[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"areaName","label":"管辖区域"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(row.enable?'':'color: #d0d0d0')},[_vm._v(_vm._s(row.areaName))])]}}])}),_c('el-table-column',{attrs:{"prop":"hierarchy","sortable":"custom","label":"层级","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(row.enable?'':'color: #d0d0d0')},[_vm._v(_vm._s(row.hierarchy))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"上级服务商","prop":"pid","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"navbar-logo-link",staticStyle:{"cursor":"pointer","color":"#28afff"},on:{"click":function($event){return _vm.set_pid(row.pid)}}},[_vm._v(_vm._s((row.pid==0? '-':row.pname)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"customersnumber","sortable":"custom","label":"直属客户数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(row.enable?'':'color: #d0d0d0')},[_vm._v(_vm._s(row.customersnumber))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"first_contact","label":"联系人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(row.enable?'':'color: #d0d0d0')},[_vm._v(_vm._s(row.first_contact))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }